import styled, { css } from 'styled-components';
import { theme } from '@theme';

import WrButton from '@commons/wr-button';
import WrLink from '@commons/wr-link';
import ModuleTitle from '@commons/module-title';

const content_spacing = '35px';
const md_content_spacing = '40px';

export const StyledTitle = styled(ModuleTitle)`
    font-weight: 800;
    text-align: center;
    margin: 0 0 ${content_spacing} 0;

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: ${md_content_spacing};
    }
`

export const StyledButton = styled(WrButton)`

  ${theme.mediaQuerys.smUp} {
        margin: 0 auto;
        min-width: 540px;
        width: 50%;
    }
`

export const ImageCtasContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-bottom: ${content_spacing};

    ${theme.mediaQuerys.mdUp} {
        padding: 0 50px;
        margin-bottom: ${md_content_spacing};
    } 
`

export const ImageCtaWrapper = styled(WrLink)`
    width: 100%;
    min-width: 250px;
    padding: 0 20px 40px 20px;

    &:last-of-type {
        padding-bottom: 0 !important;
    }

    ${theme.mediaQuerys.smUp} {
        width: calc(100% / 3);
        padding: 0 15px 15px 30px 15px;
    }

    ${theme.mediaQuerys.mdUp} {
        width: calc(100% / 3);
        padding-bottom: 5px;
    }

    ${theme.mediaQuerys.lgUp} {
        padding-bottom: 10px;
    }
`