import React from "react";

import ImageCtaCards from "./image-cta-cards";

const Start = ({ module = { display: '' } }) => {
    const { ctaCardListing } = module;
    const { display } = ctaCardListing;

    switch(display) {
        case "image-cta-cards":
            return <ImageCtaCards module={ctaCardListing} />;
        default:
            return null;
    }
};

export default Start;