import React from 'react'
import { StyledTitle, StyledButton, ImageCtasContainer, ImageCtaWrapper } from './styles';

import { StyledModuleContainer, StyledContainer } from '@styles/global-components/_containers';

import WrImage from '@commons/wr-image';

export default function ImageCtaCards({
    module
}) {
    if(module && module.ctaImages) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'lg'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="image-cta-cards"
        >
            <StyledContainer>  
                {module.title && 
                    <StyledTitle
                        titleFontOptions={{ mobile: 'md', desktop: 'sm' }}
                    > 
                        {module.title}
                    </StyledTitle>
                }
            
                {module.ctaImages && 
                    <ImageCtasContainer>
                        {module.ctaImages.map(({ image, link }, i) => (
                            <ImageCtaWrapper 
                                href={link.url}
                                key={'image-cta-card' + image.altText + link.url + i}
                            >
                                <WrImage
                                    imageFile={image.imageFile}
                                    alt={image.altText}
                                />
                            </ImageCtaWrapper>              
                        ))}
                    </ImageCtasContainer>
                }
                     
                {module.buttonLink && 
                    <StyledButton 
                        href={module.buttonLink.url} 
                        buttonOptions={module.buttonOptions}
                        fontSize="lg"
                    >
                        {module.buttonLink.title}
                    </StyledButton>
                }
            </StyledContainer>       
        </StyledModuleContainer>
    )
    return null;
}
